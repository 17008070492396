import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import "../styles/teams.scss"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Arrow from "../components/svg/arrow"

export default ({ location }) => {
  const { teams } = useStaticQuery(graphql`
    query TeamsPageQuery {
    teams: allFile(filter: {sourceInstanceName: {eq: "teams"}}) {
        edges {
          node {
            id
            childTeamsJson {
              fields {
                slug
              }
              division
              initials
              logo
              record {
                losses
                wins
              }
              socialMediaHandles {
                facebook
                instagram
                twitter
              }
              title
            }
          }
        }
      }
    }
    `)

  const alphabeticalTeams = teams.edges.sort((a, b) => a.node.childTeamsJson.title === b.node.childTeamsJson.title ? 0 : a.node.childTeamsJson.title < b.node.childTeamsJson.title ? -1 : 1)


  return (
    <Layout location={location} cssPageName="teams">
      <SEO title="USWL Teams" />
      <h1 className="title">Teams</h1>
      <section className="teams-listing">
        {alphabeticalTeams.map(team => <TeamTile
          key={team.node.childTeamsJson.initials}
          team={team.node.childTeamsJson}
          division={team.node.childTeamsJson.division}
          initials={team.node.childTeamsJson.initials}
          logo={team.node.childTeamsJson.logo}
          wins={team.node.childTeamsJson.record.wins}
          losses={team.node.childTeamsJson.record.losses}
          slug={team.node.childTeamsJson.fields.slug}
          // socialMediaHandles={team.node.childTeamsJson.socialMediaHandles}
          title={team.node.childTeamsJson.title}
        />)}
      </section>
    </Layout>
  )
}

const TeamTile = ({
  division,
  initials,
  logo,
  wins,
  losses,
  slug,
  // socialMediaHandles,
  title
}) => {
  return (

    <Link className="team-tile" to={`/teams${slug}`}>
      <h2 className="team-name">{title}</h2>
      <p className="team-initials">{initials}</p>
      <img className="team-logo" src={logo} alt={title} />
      <p className="team-record">Record: {wins}-{losses}</p>
      <p className="team-division">Division: {division}</p>
      <p className="visit">Visit <Arrow color="black" /></p>
    </Link>
  )
}